import request from '@/utils/request';
export var saveActivity = function saveActivity(params) {
  return request({
    url: '/gameSetting/activity',
    method: 'post',
    data: params
  });
};
export var updateActivity = function updateActivity(params) {
  return request({
    url: '/gameSetting/activity',
    method: 'put',
    data: params
  });
};
export var queryNotice = function queryNotice(params) {
  return request({
    url: '/gameSetting/activity',
    method: 'get',
    params: params
  });
};
export var deleteActivity = function deleteActivity(params) {
  return request({
    url: '/gameSetting/activity',
    method: 'delete',
    data: params
  });
};
export var saveMail = function saveMail(params) {
  return request({
    url: '/gameSetting/mail',
    method: 'post',
    data: params
  });
};
export var queryMail = function queryMail(params) {
  return request({
    url: '/gameSetting/mail',
    method: 'get',
    params: params
  });
};
export var saveVersion = function saveVersion(params) {
  return request({
    url: '/gameSetting/version',
    method: 'post',
    data: params
  });
};
export var updateVersion = function updateVersion(params) {
  return request({
    url: '/gameSetting/version',
    method: 'put',
    data: params
  });
};
export var queryVersion = function queryVersion(params) {
  return request({
    url: '/gameSetting/version',
    method: 'get',
    params: params
  });
};
export var deleteVersion = function deleteVersion(params) {
  return request({
    url: '/gameSetting/version',
    method: 'delete',
    data: params
  });
};
export var saveStock = function saveStock(params) {
  return request({
    url: '/gameSetting/itemStock',
    method: 'post',
    data: params
  });
};
export var queryStock = function queryStock(params) {
  return request({
    url: '/gameSetting/itemStock',
    method: 'get',
    params: params
  });
};
export var deleteStock = function deleteStock(params) {
  return request({
    url: '/gameSetting/itemStock',
    method: 'delete',
    data: params
  });
};
export var updateStock = function updateStock(params) {
  return request({
    url: '/gameSetting/itemStock',
    method: 'put',
    data: params
  });
};
export var setStockWarn = function setStockWarn(params) {
  return request({
    url: '/gameSetting/setStockWarn',
    method: 'post',
    data: params
  });
};
export var setWarnUser = function setWarnUser(params) {
  return request({
    url: '/gameSetting/setWarnUser',
    method: 'post',
    data: params
  });
};
export var getWarnUser = function getWarnUser(params) {
  return request({
    url: '/gameSetting/getWarnUser',
    method: 'get',
    data: params
  });
};
export var saveBanner = function saveBanner(params) {
  return request({
    url: '/gameSetting/banner',
    method: 'post',
    data: params
  });
};
export var queryBanner = function queryBanner(params) {
  return request({
    url: '/gameSetting/banner',
    method: 'get',
    params: params
  });
};
export var deleteBanner = function deleteBanner(params) {
  return request({
    url: '/gameSetting/banner',
    method: 'delete',
    data: params
  });
};
export var updateBanner = function updateBanner(params) {
  return request({
    url: '/gameSetting/banner',
    method: 'put',
    data: params
  });
};